const tags = {
  html: "HTML",
  css: "CSS",
  scss: "Sass",
  next: "Next.js",
  react: "React.js",
  redux: "Redux",
  mongoDB: "MongoDB",
  cpp: "C++",
  typescript: "Typescript",
  javascript: "Javascript",
  node: "Node.js",
  express: "Express.js",
  docker: "Docker",
  swarm: "Swarm",
  traefik: "Traefik",
  csharp: "C#",
  sql: "SQL",
  dotnetCore: ".Net Core",
  wasm: "WEBASSEMBLY",
  blazor: "Blazor",
  serverless: "serverless",
  googleCloud: "google cloud",
  reinforcementLearning: "reinforcement learning",
}

export default tags
