import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import resumeBuilderImg from "../images/resume_builder.png"
import devconnectorImg from "../images/devconnector.jpg"
import storybooksImg from "../images/storybooks.jpg"
import styles from "./projects.module.scss"
import cpuSimulatorImg from "../images/archi_proj3.jpg"
import tilingPuzzleImg from "../images/tiling_problem_solver.png"
import imBiasedImg from "../images/imbiased.jpg"
import trends from "../images/trends.jpg"
import scraperImg from "../images/scraper.png"
import imBiasedAPIImg from "../images/imbiased-api.jpg"
import markdownTableGeneratorImg from "../images/markdown-table-generator.png"
import padPathImg from "../images/pad_path_demo.gif"
import cryptoBalancerImg from "../images/crpyto-balancer.png"
import tags from "../config/tags"
const projects = [
  {
    name: "Monte Carlo Tree Search PAD Agent",
    image: padPathImg,
    descriptionList: [
      "An agent that solves Puzzle & Dragons boards using MCTS",
      "TODO: try reinforcement learning algorithms",
      "TODO: demo website",
    ],
    demo: "",
    github: "https://github.com/imjamesku/pad-rl",
    tags: [tags.reinforcementLearning],
  },

  {
    name: "Crypto Balancer",
    image: cryptoBalancerImg,
    descriptionList: [
      "Trading bot written in TypeScript",
      "Pulls market shares of cryptocurrencies from CoinMarketCap and balances portfolios accordingly",
      "Deployed on Google Cloud Functions & Cloud Scheduler",
    ],
    demo: "",
    github: "https://github.com/imjamesku/crypto-balancer-function",
    tags: [tags.googleCloud, tags.serverless, tags.typescript],
  },
  {
    name: "ResumeBuilder",
    image: resumeBuilderImg,
    descriptionList: [
      "Resume builder app built with react-pdf",
      "Real time pdf preview that provides instant feedback",
      "Fully customizable sections that can be easily modified",
    ],
    demo: "https://resume-builder-demo.vercel.app/",
    tags: [tags.next, tags.react, tags.typescript, tags.scss],
  },
  {
    name: "imbiased",
    image: imBiasedImg,
    descriptionList: [
      "Voting platform implemented with Next.js in Typescript",
      "Supports authentication, creating topics and comments",
      "Protects against spam and abuse with google reCAPTCHA",
      "Loads topics with infinite scrolling",
    ],
    github: "https://github.com/imjamesku/biased",
    demo: "https://biased.vercel.app",
    tags: [tags.next, tags.react, tags.redux, tags.typescript, tags.scss],
  },
  {
    name: "imbiased Backend API",
    image: imBiasedAPIImg,
    descriptionList: [
      "Restful API service implemented with .Net Core",
      "Implemented the code with the Repository-Service Pattern and DI",
      "Deployed with docker swarm mode as a service with traefik as reverse proxy",
      "Supports authentication, creating topics, comments, reCAPTCHA verification, pagination, etc.",
      "Connects to a PostgreSQL database",
    ],
    github: "https://github.com/imjamesku/biased-api",
    tags: [
      tags.csharp,
      tags.dotnetCore,
      tags.docker,
      tags.swarm,
      tags.sql,
      tags.traefik,
    ],
  },
  {
    name: "Trending Topics",
    image: trends,
    descriptionList: [
      "App to view historical daily trends collected by Daily Trend Scraper",
      "Implemented in typescript with next.js, a react framework that supports server-side rendering",
      "Uses server side rendering for the index page",
    ],
    github: "https://github.com/imjamesku/trending-topics",
    demo: "https://daily-trends.now.sh/",
    tags: [tags.react, tags.next, tags.typescript, tags.scss, tags.html],
  },
  {
    name: "Daily Trend Scraper",
    image: scraperImg,
    descriptionList: [
      "Automatically collects data from google trends into a database",
      "Implemented with node.js and typescript",
      "Uses node-cron to collect google trends data every hour",
      "Inspired by the observation that google trends only supports looking up data up to 3 weeks ago. I wrote this app to keep historical data, allowing users to look up trends further in the past",
    ],
    github: "https://github.com/imjamesku/daily-trends-scraper",
    demo: null,
    tags: [tags.typescript, tags.node, tags.mongoDB],
  },
  {
    name: "Markdown Table Generator",
    image: markdownTableGeneratorImg,
    descriptionList: [
      "Markdown table generator built with blazor webassembly",
      "Uses C# data structures to manage 2d array state more efficiently",
    ],
    github: "https://github.com/imjamesku/markdown-table-generator",
    demo: "https://markdown-table.netlify.app/",
    tags: [
      tags.csharp,
      tags.dotnetCore,
      tags.blazor,
      tags.wasm,
      tags.scss,
      tags.html,
    ],
  },
  {
    name: "DevConnector",
    image: devconnectorImg,
    descriptionList: [
      "Social network for developers for to create resumes to showcase their experience, skills and education",
      "Supports creating and editing posts, commenting on posts, and liking/unliking posts",
      "Implemented RESTful backend API with Node.js and MongoDB, and frontend with React and Redux",
      "Automatically fetches users repositories from Github and displays them on the profile page",
    ],
    github: "https://github.com/imjamesku/devconnector",
    demo: "https://james-devconnector.herokuapp.com/",
    tags: [
      tags.react,
      tags.html,
      tags.css,
      tags.javascript,
      tags.node,
      tags.express,
      tags.mongoDB,
    ],
  },
  {
    name: "storybooks",
    image: storybooksImg,
    descriptionList: [
      "Online platform where user can share their stories and leave comments on posts",
      "Used Node.js and MongoDB to build backend API to store users’ stories and comments",
      "Uses passport and google authentication for user login",
    ],
    github: "https://github.com/imjamesku/storybooks",
    demo: "https://james-storybooks.herokuapp.com/",
    tags: [
      tags.react,
      tags.html,
      tags.css,
      tags.javascript,
      tags.node,
      tags.express,
      tags.mongoDB,
    ],
  },
  {
    name: "Tiling Puzzle solver",
    image: tilingPuzzleImg,
    descriptionList: [
      "Tiling puzzle solver visualized with React",
      "Supports custom input files",
    ],
    github: "https://github.com/imjamesku/react-tiling-puzzle-solver",
    demo: "https://imjamesku.github.io/react-tiling-puzzle-solver/",
    tags: [tags.react, tags.html, tags.css, tags.javascript],
  },
  {
    name: "CPU simulator",
    image: cpuSimulatorImg,
    descriptionList: [
      "Processor simulator that imitates the behavior of a MIPS processor in running instructions in C++",
      "Implemented functionalities such as forwarding, pipelining, TLB and a caching algorithm",
    ],
    github: "https://github.com/imjamesku/pipelined_simulator",
    demo: null,
    tags: ["C++"],
  },
]

const Projects = () => {
  return (
    <Layout>
      <SEO
        title="My Projects"
        description="See the projects I've built"
        keywords={[
          `web development`,
          `projects`,
          `Jia Jiun Ku`,
          `react projects`,
        ]}
      />
      <h1>My Projects</h1>
      <div className={styles.projectContainer}>
        {projects.map((project, index) => (
          <div key={index} className={styles.projectBox}>
            {project.demo ? (
              <a href={project.demo} target="_blank" rel="noopener noreferrer">
                <img variant="top" src={project.image} alt={project.name} />
              </a>
            ) : (
              <img variant="top" src={project.image} alt={project.name} />
            )}
            <div className={styles.projectBoxText}>
              <div>
                <h2>{project.name}</h2>
                <div className={styles.tagContainer}>
                  {project.tags.map((tag, index) => (
                    <span className={styles.tag} key={index}>
                      {tag}
                    </span>
                  ))}
                </div>
                <ul>
                  {project.descriptionList.map((description, index) => (
                    <li key={index}>{description}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.projectBoxLinks}>
                {project.github && (
                  <a
                    className="btn-secondary"
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Github
                  </a>
                )}
                {project.demo && (
                  <a
                    className="btn-action"
                    href={project.demo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Demo
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Projects
